import * as React from "react"
import { useFormik } from "formik"
import * as yup from 'yup';
import {Button, Pagination, FormControl, Typography, InputLabel, MenuItem, Slider, Container, AppBar, Toolbar, IconButton, Paper } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
//import ChevronRightIcon from '@mui/icons-material/ChevronRight';
//import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app"
import { getFirestore, getDoc, doc} from "firebase/firestore";
import { StaticImage } from "gatsby-plugin-image"
import { navigate, Link } from 'gatsby'
import * as queryString from "query-string";
import beef from '../images/beef.png';
import chicken from '../images/chicken.png';
import bison from '../images/bison.png';
import chicken_beef from '../images/chicken-beef.png';

const firebaseConfig = {
  apiKey: "AIzaSyAphO2oMz0XPzeo4dFHHNmGTcxgxW8hoqI",
  authDomain: "natural-dog-food.firebaseapp.com",
  projectId: "natural-dog-food",
  storageBucket: "natural-dog-food.appspot.com",
  messagingSenderId: "367062847841",
  appId: "1:367062847841:web:828b59f872eb889e66f520",
  measurementId: "G-42E61CHK1M"
};


const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);
const db = getFirestore();

async function GetFirebaseData(values, recipe) {
  var recipeType = recipe;
  var dogWeight = values["selectWeight"]
  const docRef = doc(db, "recipes", recipeType+"/recipe-table/"+dogWeight);
  console.log("Dog weight"+dogWeight);
  console.log("recipe type"+ recipeType);
  //const [statePrice, setStatePrice] = React.useState(0);
  //const historyRouter = useHistory();
  const docSnap = await getDoc(docRef);
  if (docSnap.exists()) {
    const formData = docSnap.data();
    console.log("Document data:", docSnap.data()["price"]);
    //setStatePrice(docSnap.data()["price"]);
    var stateValues = [formData["price"], recipeType, dogWeight];
    navigate('/displayOptions?price='+formData["price"]+"&recipeType="+recipeType+"&dw="+dogWeight,
    {
      state: { stateValues },
    });
  } else {
    // doc.data() will be undefined in this case
    console.log("No such document!");
  }


}
const validationSchema = yup.object({
  selectFormulation: yup
    .string()
    .required('Formulation selection is required'),
  });
function FindIdealProductForm(props){
  const recipeMax = props.recipeMax;
  const recipe = props.recipeType;
  const formik = useFormik({
    initialValues: {
      selectFormulation: "Chicken",
      selectWeight: 10,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      GetFirebaseData(values, recipe);
    },
  });
  return (
    <form onSubmit={formik.handleSubmit}>
      <InputLabel htmlFor="selectWeight">Select the weight of the dog</InputLabel>
      <Slider 
        defaultValue={10}
        id="selectWeight"
        name="selectWeight"
        label="Select Dog Weight"
        value={formik.values.selectWeight}
        onChange={formik.handleChange}
        error={formik.touched.selectWeight && Boolean(formik.errors.selectWeight)}
        helperText={formik.touched.selectWeight && formik.errors.selectWeight}
        step={5}
        marks
        min={10}
        max={recipeMax}
        valueLabelDisplay="on"
      />
      <Button variant="outlined" type="submit">Find your ideal dog food!</Button>
    </form>
  )
}





// markup
const SelectWeightPage = ({location}) => {
  //console.log(location);
  const recipeType = queryString.parse(location.search);
  var recipe = recipeType["recipeType"];
  //if (location.state) {
    const handleChange = (event, value) => {
      if (value == 1) {
        navigate('/');
      }
    }
    //const recipe = location.state.recipe;
    var disp_recipe;
    var recipeMax;
    var fRecipe;
    if (recipe == "chicken_beef") {
      disp_recipe = "Chicken Beef";
      fRecipe = chicken_beef;
      recipeMax = 120;
    } else if (recipe == "chicken") {
      disp_recipe = "Chicken";
      fRecipe = chicken;
      recipeMax = 95;
    } else if (recipe == "beef" ) {
      disp_recipe = "Beef";
      fRecipe = beef;
      recipeMax = 120;
    } else {
      disp_recipe = "Bison"
      fRecipe = bison;
      recipeMax = 95;
    }
    const image_path = fRecipe +".png";
    return (
      <main>
        <Typography variant="h1" style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',}}>{disp_recipe}
        </Typography>
        <Paper variant="outlined">
          <img style={{width: '95vw'}} src={fRecipe} />
        </Paper>
        <FindIdealProductForm recipeType={recipe} recipeMax={recipeMax} style={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center' }} />

<AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0 }}>
          <Toolbar>
            <IconButton href="http://braxtonsnaturaldogfood.com/">
              <ChevronLeftIcon>  </ChevronLeftIcon>
            </IconButton>
            <Pagination count={2} page={2}onChange={handleChange} />
          </Toolbar>
        </AppBar>
      </main>
    );
    /*
  } else {
    return (
      <main>
      <h1>That was an error.</h1>
      <p>Did you get here by typing this page into your address bar? If so, please follow this link back to the beginning of the form.</p>
      <Link to="/">
        Form start
      </Link>
    </main>
    );

    
  }
  */

}
export default SelectWeightPage